import {Component, Inject, Optional} from '@angular/core';
import {BuilderStateService} from '../../../../builder-state.service';
import {ImageElementService} from '../../../image-element.service';
import {ProjectUrl} from '../../../../../shared/projects/project-url.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ContactPersonsModel} from '../contact-persons-widget.model';
import {CkEditor5} from '@common/text-editor/ckeditor5/CkEditor5';
import {CKEditor5} from '@ckeditor/ckeditor5-angular';

export interface ContactPersonWidgetEditorModalComponentData {
    widget: ContactPersonsModel;
}

@Component({
    selector: 'contact-persons-widget-editor-modal',
    templateUrl: './contact-persons-widget-editor-modal.component.html',
    styleUrls: ['./contact-persons-widget-editor-modal.component.scss'],
})
export class ContactPersonsWidgetEditorModalComponent {

    public processing = false;

    constructor(
        public ckEditor5: CkEditor5,
        private projectUrl: ProjectUrl,
        private state: BuilderStateService,
        private imageElementService: ImageElementService,
        @Inject(MAT_DIALOG_DATA)
        public data: ContactPersonWidgetEditorModalComponentData,
        @Inject(MatDialogRef)
        @Optional()
        private modalRef: MatDialogRef<ContactPersonsWidgetEditorModalComponent>
    ) {
    }

    public submit(): void {
        this.sanitizeText();
        this.close(this.data.widget);
    }

    public close(value?: ContactPersonsModel): void {
        this.modalRef.close(value);
    }

    public getPrimaryImageSrc(card: ContactPersonsModel): string | undefined {
        if (card.primaryImageSrc == null || card.primaryImageSrc.length === 0) {
            return undefined;
        }
        return `${this.projectUrl.getBaseUrl(this.state.project$.value.model)}/${card.primaryImageSrc}`;
    }

    public updatePrimaryImage(card: ContactPersonsModel): void {
        this.processing = true;
        this.imageElementService.openModal().subscribe((url: string) => {
            this.processing = false;
            if (!url) return;
            card.primaryImageSrc = url;
        });
    }

    public getHoverImageSrc(card: ContactPersonsModel): string | undefined {
        if (card.hoverImageSrc == null || card.hoverImageSrc.length === 0) {
            return undefined;
        }
        return `${this.projectUrl.getBaseUrl(this.state.project$.value.model)}/${card.hoverImageSrc}`;
    }

    public updateHoverImage(card: ContactPersonsModel): void {
        this.processing = true;
        this.imageElementService.openModal().subscribe((url: string) => {
            this.processing = false;
            if (!url) return;
            card.hoverImageSrc = url;
        });
    }

    public deleteHoverImage(card: ContactPersonsModel): void {
        card.hoverImageSrc = undefined;
    }

    public get editorConfigMinimal(): CKEditor5.Config {
        return {
            ...this.ckEditor5.configMinimal
        };
    }

    public editorReady(editor: any): void {
        // Prevents HTML paragraph Tags from being inserted where we don't want them
        editor.editing.view.document.on( 'enter', (evt, data ) => {
            data.preventDefault();
            evt.stop();

            editor.execute( 'shiftEnter' );
            editor.editing.view.scrollToTheSelection();
        }, { priority: 'high' } );
    }

    private sanitizeText() {
        if (this.data.widget.text.startsWith('<p>')) {
            this.data.widget.text = this.data.widget.text.substring('<p>'.length);
        }

        if (this.data.widget.text.endsWith('</p>')) {
            this.data.widget.text = this.data.widget.text.substring(0, this.data.widget.text.length - '</p>'.length);
        }

        this.data.widget.text = this.data.widget.text.trim();
    }
}
