import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {duplicateTestimonial, TestimonialWidget, TestimonialWidgetElement} from '../testimonial-widget.model';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {ComponentSubscriptionService} from '@common/core/utils/component-subscription-service';
import {ConfirmModalComponent} from '@common/core/ui/confirm-modal/confirm-modal.component';
import {Modal} from '@common/core/ui/dialogs/modal.service';
import {Settings} from '@common/core/config/settings.service';
import {OverlayPanel} from '@common/core/ui/overlay-panel/overlay-panel.service';
import {CurrentUser} from '@common/auth/current-user';
import {ProjectUrl} from '../../../../../shared/projects/project-url.service';
import {BuilderStateService} from '../../../../builder-state.service';
import {ImageElementService} from '../../../image-element.service';
import {CkEditor5} from '@common/text-editor/ckeditor5/CkEditor5';

@Component({
    selector: 'testimonial-widget-editor',
    templateUrl: './testimonial-widget-editor.component.html',
    styleUrls: ['./testimonial-widget-editor.component.scss'],
})
export class TestimonialWidgetEditorComponent implements OnDestroy {

    @Input() testimonialWidget: TestimonialWidget;
    @Output() processing = new EventEmitter<boolean>();

    _processing = false;
    _subscriptions = new ComponentSubscriptionService();

    constructor(
        private projectUrl: ProjectUrl,
        private state: BuilderStateService,
        private imageElementService: ImageElementService,
        public ckEditor5: CkEditor5,
        public modal: Modal,
        public panel: OverlayPanel,
        public settings: Settings,
        public currentUser: CurrentUser,
    ) {
        this._subscriptions.add(this.processing.subscribe(p => this._processing = p));
    }

    ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }

    drop(event: CdkDragDrop<string[]>): void {
        moveItemInArray(this.testimonialWidget.elements, event.previousIndex, event.currentIndex);
    }

    addTestimonial(): void {
        const newTestimonial = new TestimonialWidgetElement();
        this.testimonialWidget.elements.push(newTestimonial);
    }

    duplicateTestimonial(slideIndex: number): void {
        const newTestimonial = duplicateTestimonial(this.testimonialWidget.elements[slideIndex]);
        this.testimonialWidget.elements.splice(slideIndex + 1, 0, newTestimonial);
    }

    removeTestimonial(slideIndex: number): void {
        this.modal.open(ConfirmModalComponent, {
            title: 'Remove slide',
            body: 'Are you sure you want to remove this slide?',
            ok: 'Remove',
        }).afterClosed().subscribe(confirmed => {
            if (!confirmed) return;

            this.testimonialWidget.elements.splice(slideIndex, 1);
        });
    }

    isNumber(n: number | string): boolean {
        return Number.isInteger(n);
    }

    canMoveTestimonialUp(slideIndex: number): boolean {
        return slideIndex > 0;
    }

    moveTestimonialUp(slideIndex: number): void {
        const slide = this.testimonialWidget.elements[slideIndex];
        this.testimonialWidget.elements[slideIndex] = this.testimonialWidget.elements[slideIndex - 1];
        this.testimonialWidget.elements[slideIndex - 1] = slide;
    }

    canMoveTestimonialDown(slideIndex: number): boolean {
        return slideIndex < this.testimonialWidget.elements.length - 1;
    }

    moveTestimonialDown(slideIndex: number): void {
        const slide = this.testimonialWidget.elements[slideIndex];
        this.testimonialWidget.elements[slideIndex] = this.testimonialWidget.elements[slideIndex + 1];
        this.testimonialWidget.elements[slideIndex + 1] = slide;
    }

    getPrimaryImageSrc(testimonial: TestimonialWidgetElement): string | undefined {
        if (testimonial.imageSrc == null || testimonial.imageSrc.length === 0) {
            return undefined;
        }

        return `${this.projectUrl.getBaseUrl(this.state.project$.value.model)}/${testimonial.imageSrc}`;
    }

    updatePrimaryImage(testimonial: TestimonialWidgetElement): void {
        this.processing.next(true);
        this.imageElementService.openModal().subscribe((url: string) => {
            this.processing.next(false);
            if (!url) return;
            testimonial.imageSrc = url;
        });
    }

    deletePrimaryImage(testimonial: TestimonialWidgetElement): void {
        testimonial.imageSrc = undefined;
    }
}
