import {ArchitectElement} from '../../architect-element';
import {MutationsService} from '../../../mutations/mutations.service';
import {template as singleContactPersonTemplate} from './contact-persons-widget';
import {InsertNode} from '../../../mutations/dom/insert-node';
import {
    ContactPersonContainerWidgetEditorModalComponentData
} from './contact-persons-container-widget-editor-modal/contact-persons-container-widget-editor-modal.component';
import {ContactPersonsContainerWidgetEditor} from './contact-persons-container-widget-editor.service';
import {ReplaceNodeContent} from '../../../mutations/dom/replace-node-content';
import {ModifyAttributes} from '../../../mutations/attributes/modify-attributes';
import {randomString} from '@common/core/utils/random-string';

const template = (): string => {
    const id = randomString(10);

    return `<div id="${id}" class="sb-contact-person-container" data-elements="4">
        <style>
            ${buildStyleString(id, 4)}
        </style>
        ${singleContactPersonTemplate}
        ${singleContactPersonTemplate}
        ${singleContactPersonTemplate}
    </div>
`;
};

export class ContactPersonsContainerWidgetEl extends ArchitectElement {
    name = 'Contact Persons';
    contentCategories = ['flow'];
    html = template;
    allowedContent = ['contact-person'];
    category = 'components';
    icon = 'account-box';
    specificity = 5;
    hiddenClasses = ['sb-contact-person-container'];
    editActions = [
        {
            preventActionIds: ['action.text.edit'],
            name: 'Add contact person',
            onClick: (node: HTMLElement) => {
                const newElement =  new DOMParser().parseFromString(singleContactPersonTemplate, 'text/html').querySelector('.contact-person') as HTMLElement;

                this.inject(MutationsService).execute(
                    new InsertNode(newElement, node.children.length, node.dataset.arId)
                );
            },
        },
        {
            preventActionIds: ['action.layout.edit'],
            name: 'Change style',
            onClick: (node: HTMLElement) => {
                const elementsPerRow: number = parseInt(node.dataset.elements);

                this.inject(ContactPersonsContainerWidgetEditor)
                    .open({elementsPerRow})
                    .afterClosed()
                    .subscribe((data: ContactPersonContainerWidgetEditorModalComponentData) => {
                        const styleNode = node.querySelector('style');

                        this.inject(MutationsService).execute(
                            new ReplaceNodeContent(styleNode, styleNode.innerHTML, buildStyleString(node.id, data.elementsPerRow))
                        );

                        this.inject(MutationsService).execute(
                            new ModifyAttributes(node, {
                                'data-elements': `${data.elementsPerRow}`
                            })
                        );
                    });
            },
        },
    ];

    matcher(node: HTMLElement) {
        return node.closest('.sb-contact-person-container') as HTMLElement;
    }
}

function buildStyleString(id: string, elementsPerRow: number): string {
    return `#${id} {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
        }

        @media (min-width: 768px) {
            #${id} {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
            }
        }

        @media (min-width: 1200px) {
            #${id} {
                display: grid;
                grid-template-columns: repeat(${elementsPerRow}, 1fr);
            }
        }
        `;
}
