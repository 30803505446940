export class TestimonialWidget {
    public id: string;
    public elements: TestimonialWidgetElement[] = [];
    public interval?: number;
    public showControls = true;
    public showIndicators = true;
}

export class TestimonialWidgetElement {
    public person = '';
    public quote = '';
    public position: number | string = 'last';
    public imageSrc: string | undefined;
    public imageLink: string | undefined;
}

/**
 * Duplicates a testimonial
 * @param testimonial The testimonial to duplicate
 * @returns The duplicated testimonial
 */
export function duplicateTestimonial(testimonial: TestimonialWidgetElement): TestimonialWidgetElement {
    return {
        ...testimonial,
    };
}
