import {Component, Inject, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface ContactPersonContainerWidgetEditorModalComponentData {
    elementsPerRow: number;
}

@Component({
    selector: 'contact-persons-container-widget-editor-modal',
    templateUrl: './contact-persons-container-widget-editor-modal.component.html',
    styleUrls: ['./contact-persons-container-widget-editor-modal.component.scss'],
})
export class ContactPersonsContainerWidgetEditorModalComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA)
        @Optional()
        public data: ContactPersonContainerWidgetEditorModalComponentData,
        @Inject(MatDialogRef)
        @Optional()
        public modalRef: MatDialogRef<ContactPersonsContainerWidgetEditorModalComponent>
    ) {
    }

    public submit(): void {
        this.close(this.data);
    }

    public close(value?: ContactPersonContainerWidgetEditorModalComponentData): void {
        this.modalRef.close(value);
    }
}
