import {TestimonialWidget, TestimonialWidgetElement} from './testimonial-widget.model';

export function extractTestimonialWidget(testimonialMarkupEl: HTMLElement): TestimonialWidget {
    const testimonialWidget = new TestimonialWidget();

    testimonialWidget.id = testimonialMarkupEl.id;

    const configEl = testimonialMarkupEl.querySelector('.carousel__config') as HTMLElement;

    if (configEl != null) {
        testimonialWidget.interval = !Number.isNaN(Number.parseInt(configEl.dataset.interval)) ? Number.parseInt(configEl.dataset.interval) : undefined;
        testimonialWidget.showIndicators =  (configEl.dataset.showindicators !== undefined) ? (configEl.dataset.showindicators === 'true') : true;
        testimonialWidget.showControls =  (configEl.dataset.showcontrols !== undefined) ? (configEl.dataset.showcontrols === 'true') : true;
    }

    const testimonialEls = testimonialMarkupEl.querySelectorAll('.carousel-item');
    for (let i = 0; i < testimonialEls.length; i++) {
        const testimonialEl = testimonialEls.item(i);
        const testimonialWidgetItem = extractTestimonialItem(testimonialEl as HTMLElement);
        testimonialWidget.elements.push(testimonialWidgetItem);
    }
    return testimonialWidget;
}

function extractTestimonialItem(testimonialEl: HTMLElement): TestimonialWidgetElement {
    const quoteEl = testimonialEl.querySelector('.testimonial-quote') as HTMLElement;
    const personEl = testimonialEl.querySelector('.blockquote-footer') as HTMLElement;
    const imageLinkEl = testimonialEl.querySelector('a') as HTMLAnchorElement;
    const imageEl = testimonialEl.querySelector('.img-thumbnail') as HTMLElement;

    const testimonial = new TestimonialWidgetElement();
    testimonial.quote = quoteEl.innerHTML;
    testimonial.person = personEl.textContent;

    if (imageEl !== null) {
        testimonial.imageSrc = imageEl.attributes.getNamedItem('src').value;
    }

    if (imageLinkEl !== null) {
        testimonial.imageLink = imageLinkEl.href;
    }

    return testimonial;
}
