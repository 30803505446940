import {ArchitectElement} from '../../architect-element';
import {MutationsService} from '../../../mutations/mutations.service';
import {TestimonialWidget} from './testimonial-widget.model';
import {TestimonialWidgetEditorModal} from './builder/testimonial-widget-editor-modal.service';
import {ReplaceNodeContent} from '../../../mutations/dom/replace-node-content';
import {generateTestimonialWidgetMarkup} from './testimonial-widget-markup-generator';
import {extractTestimonialWidget} from './testimonial-widget-markup-extractor';
import {randomString} from '@common/core/utils/random-string';
import {ModifyAttributes, NodeAttributes} from '../../../mutations/attributes/modify-attributes';

function generateTemplate(): string {
    const testimonialId = randomString(10);

    return `<div id="${testimonialId}" class="sb-testimonials-widget carousel slide mt-5 mb-5 text-start text-left p-5 bg-white shadow rounded" data-ride="carousel" data-bs-ride="carousel">
    <div class="carousel__config" data-interval="" data-showindicators="true" data-showcontrols="true" data-ride="carousel"></div>
    <ol class="carousel-indicators mb-0">
        <li data-target="#testemonialCarousel" data-bs-target="#testemonialCarousel" data-slide-to="0" data-bs-slide-to="0" class="active"></li>
        <li data-target="#testemonialCarousel" data-bs-target="#testemonialCarousel" data-slide-to="1" data-bs-slide-to="1"></li>
    </ol>
    <div class="carousel-inner px-5 pb-4">
        <div class="carousel-item active">
            <div class="media">
                <div class="media-body">
                    <blockquote class="blockquote border-0 p-0">
                        <div class="testimonial-quote">
                            <p class="font-italic lead">"Es war toll mit Euch zu arbeiten!"</p>
                        </div>
                        <footer class="blockquote-footer mt-3">Max Mustermann<br></footer>
                    </blockquote>
                </div>
            </div>
        </div>
        <div class="carousel-item">
            <div class="media">
                <div class="media-body">
                    <blockquote class="blockquote border-0 p-0">
                        <div class="testimonial-quote">
                            <p class="font-italic lead">"Ich habe es sehr genossen!"</p>
                        </div>
                        <footer class="blockquote-footer mt-3">Andrea Andersnamig<br></footer>
                    </blockquote>
                </div>
            </div>
        </div>
    </div>
    <a class="carousel-control-prev width-auto" href="#${testimonialId}" role="button" data-slide="prev" data-bs-slide="prev">
        <i class="fa fa-angle-left text-dark text-lg follow-through"></i>
        <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next width-auto" href="#${testimonialId}" role="button" data-slide="next" data-bs-slide="next">
        <i class="fa fa-angle-right text-dark text-lg follow-through"></i>
        <span class="sr-only">Next</span>
    </a>
</div>`;
}

function generateTestimonialWidgetAttributes(testimonialWidget: TestimonialWidget): NodeAttributes {
    const result: NodeAttributes = {};
    if (testimonialWidget.interval !== undefined) {
        result['data-interval'] = testimonialWidget.interval.toString();
    }
    return result;
}

export class TestimonialWidgetEl extends ArchitectElement {
    name = 'Testimonials';
    contentCategories = ['flow'];
    html = generateTemplate;
    allowedContent = [];
    category = 'components';
    icon = 'format-quote';
    specificity = 5;
    hiddenClasses = ['carousel', 'slide', 'sb-testimonials-widget'];
    editActions = [
        {
            preventActionIds: ['action.text.edit'],
            name: 'Edit',
            onClick: (node: HTMLElement) => {
                const widget = extractTestimonialWidget(node);
                this.inject(TestimonialWidgetEditorModal)
                    .open(node, widget)
                    .afterClosed()
                    .subscribe((updatedWidget?: TestimonialWidget) => {
                        if (updatedWidget != null) {
                            const newInnerHTML = generateTestimonialWidgetMarkup(updatedWidget);
                            const newAttributes = generateTestimonialWidgetAttributes(updatedWidget);
                            this.inject(MutationsService).execute(
                                new ReplaceNodeContent(node, node.innerHTML, newInnerHTML)
                            );
                            this.inject(MutationsService).execute(
                                new ModifyAttributes(node, newAttributes)
                            );
                        }
                    });
            },
        },
    ];

    matcher(node: HTMLElement): HTMLElement {
        return node.closest('.sb-testimonials-widget') as HTMLElement;
    }
}
