import {ContactPersonsModel} from './contact-persons-widget.model';

export function extractCardCarouselWidgetCard(cardMarkupEl: HTMLElement): ContactPersonsModel {
    const result = new ContactPersonsModel();

    const imageEl = cardMarkupEl.querySelector('img') as HTMLImageElement;
    const textEl = cardMarkupEl.querySelector('p') as HTMLParagraphElement;

    result.name = textEl.querySelector('.sb-name').textContent;
    result.text = parseText(textEl.innerHTML);

    result.primaryImageSrc = imageEl.dataset.primary;
    result.hoverImageSrc = imageEl.dataset.switch;
    if (result.hoverImageSrc === '') {
        result.hoverImageSrc = undefined;
    }
    result.imageAlt = imageEl.alt;

    return result;
}

function parseText(content: string): string {
    return content.trim()
        .replace(/<span.*>.*<\/span>/, '') // Removes the element storing .sb-name
        .replace(/^<br.*?>/, '') // Removes a newline that is added after the .sb-name, so the actual text doesn't start with a newline
        .replace(/<br.*?>$/, '') // Removes a trailing newline
        .replace(/<br>$\s+/gm, '<br>') // Removes any spaces and tabs after newlines so that each line doesn't start with a set of indentations resulting from pretty formatting HTML
        .trim();
}
