<div cdkDropList class="list" (cdkDropListDropped)="drop($event)">

    <div class="row">
        <div class="col-grow many-inputs">
            <div class="input-container">
                <label for="interval" trans>Interval (ms)</label>
                <input type="number" id="interval" name="interval" [(ngModel)]="testimonialWidget.interval"
                       [disabled]="_processing"/>
                <mat-hint trans>Leave empty for default value (5000). 0 to disable auto-play.</mat-hint>
            </div>

            <mat-slide-toggle [(ngModel)]="testimonialWidget.showIndicators" trans>
                Show Indicators
            </mat-slide-toggle>

            <mat-slide-toggle [(ngModel)]="testimonialWidget.showControls" trans>
                Show Controls
            </mat-slide-toggle>
        </div>
    </div>

    <div class="list-item" *ngFor="let testimonial of testimonialWidget.elements; let i = index" cdkDrag
         [cdkDragDisabled]="_processing">
        <button cdkDragHandle mat-icon-button class="drag-handle">
            <mat-icon svgIcon="drag-handle" matListIcon></mat-icon>
        </button>
        <div class="row">
            <div class="col-grow">
                <div class="row">
                    <div class="col-grow slide-actions">
                        <button type="button" mat-icon-button *ngIf="canMoveTestimonialUp(i)"
                                (click)="moveTestimonialUp(i)" [matTooltip]="'Move up' | trans" [disabled]="_processing">
                            <mat-icon svgIcon="move-up"></mat-icon>
                        </button>
                        <button type="button" mat-icon-button *ngIf="canMoveTestimonialDown(i)"
                                (click)="moveTestimonialDown(i)" [matTooltip]="'Move down' | trans" [disabled]="_processing">
                            <mat-icon svgIcon="move-down"></mat-icon>
                        </button>
                        <button type="button" mat-icon-button
                                (click)="duplicateTestimonial(i)" [matTooltip]="'Duplicate testimonial' | trans" [disabled]="_processing">
                            <mat-icon svgIcon="content-copy"></mat-icon>
                        </button>
                        <button type="button" mat-icon-button
                                (click)="removeTestimonial(i)" [matTooltip]="'Remove testimonial' | trans" [disabled]="_processing">
                            <mat-icon svgIcon="delete"></mat-icon>
                        </button>
                    </div>
                </div>

                <div class="row">
                    <div class="col col-grow many-inputs">
                        <div class="input-container">
                            <input type="text" id="title" name="title" placeholder="Title" [(ngModel)]="testimonial.person"
                                      [disabled]="_processing"/>
                        </div>

                        <div class="input-container">
                            <ckeditor id="quote"
                                      [(ngModel)]="testimonial.quote"
                                      [editor]="ckEditor5.Editor"
                                      [config]="ckEditor5.configMinimal"></ckeditor>
                        </div>

                        <div class="input-container">
                            <label trans>Image</label>
                            <div class="action-buttons">
                                <button type="button" class="action-button" mat-stroked-button color="accent"
                                        (click)="!_processing && updatePrimaryImage(testimonial)" trans>Select
                                </button>
                                <button type="button" class="action-button delete-button" mat-icon-button
                                        [matTooltip]="'Remove background image' | trans"
                                        *ngIf="getPrimaryImageSrc(testimonial)"
                                        (click)="!_processing && deletePrimaryImage(testimonial)">
                                    <mat-icon svgIcon="delete"></mat-icon>
                                </button>
                            </div>
                            <div class="image-preview"
                                 *ngIf="getPrimaryImageSrc(testimonial)"
                                 (click)="!_processing && updatePrimaryImage(testimonial)"
                                 [matTooltip]="'Change image' | trans">
                                <img class="image-preview"
                                     [src]="getPrimaryImageSrc(testimonial)"
                                     [alt]="testimonial.person">
                            </div>
                        </div>
                        <div class="input-container">
                            <label trans>Link</label>
                            <input type="text" id="link" name="link" placeholder="Link" [(ngModel)]="testimonial.imageLink"
                                   [disabled]="_processing"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
