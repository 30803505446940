import {ContactPersonsModel} from './contact-persons-widget.model';

export function generateCardCarouselWidgetCardMarkup(card: ContactPersonsModel): string {
    return`<img src="${card.primaryImageSrc}"
                alt="${card.imageAlt}"
                class="sb-img-circle"
                data-primary="${card.primaryImageSrc}"
                data-switch="${card.hoverImageSrc ?? ''}">
            <p class="mt-3"
                style="word-break: break-all">
                <span class="sb-name">${card.name}</span><br>
                ${card.text}
            </p>`;
}

