<div>
    <div class="modal-header">
        <h2 class="modal-title" mat-dialog-title trans>Contact Person</h2>
        <button type="button" (click)="close()" class="close-button no-style" tabindex="-1">
            <mat-icon svgIcon="close"></mat-icon>
        </button>
    </div>
    <loading-bar *ngIf="processing"></loading-bar>
</div>

<mat-dialog-content>
    <div class="row">
        <div class="col">
            <div class="input-container">
                <label for="name" trans>Name</label>
                <input type="text" id="name" name="name" [(ngModel)]="data.widget.name" [disabled]="processing">
            </div>
            <div class="input-container">
                <label for="text" trans>Text</label>
                <ckeditor id="text"
                          (ready)="editorReady($event)"
                          [(ngModel)]="data.widget.text"
                          [disabled]="processing"
                          [editor]="ckEditor5.Editor"
                          [config]="editorConfigMinimal"></ckeditor>
            </div>
        </div>
        <div class="col">
            <div>
                <label trans>Image</label>
                <div class="image-preview"
                     *ngIf="getPrimaryImageSrc(data.widget)"
                     (click)="!processing && updatePrimaryImage(data.widget)"
                     [matTooltip]="'Change image' | trans">
                    <img [src]="getPrimaryImageSrc(data.widget)" [alt]="data.widget.imageAlt">
                </div>
                <div class="action-buttons">
                    <button type="button" class="action-button" mat-stroked-button color="accent"
                            (click)="!processing && updatePrimaryImage(data.widget)" trans>Select
                    </button>
                </div>
            </div>
            <div>
                <label trans>Hover image</label>
                <div class="image-preview"
                     *ngIf="getHoverImageSrc(data.widget)"
                     (click)="!processing && updatePrimaryImage(data.widget)"
                     [matTooltip]="'Change image' | trans">
                    <img [src]="getHoverImageSrc(data.widget)" [alt]="data.widget.imageAlt">
                </div>
                <div class="action-buttons">
                    <button type="button" class="action-button" mat-stroked-button color="accent"
                            (click)="!processing && updateHoverImage(data.widget)" trans>Select
                    </button>
                    <button type="button" class="action-button delete-button" mat-icon-button
                            [matTooltip]="'Remove image' | trans"
                            *ngIf="getHoverImageSrc(data.widget)"
                            (click)="!processing && deleteHoverImage(data.widget)">
                        <mat-icon svgIcon="delete"></mat-icon>
                    </button>
                </div>
            </div>
            <div class="input-container">
                <label for="imageAlt" trans>Alt label</label>
                <input type="text" id="imageAlt" name="imageAlt" [(ngModel)]="data.widget.imageAlt"
                       [disabled]="processing">
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button (click)="close()" type="button" mat-button trans>Cancel</button>
    <button (click)="submit()" mat-raised-button color="accent" [disabled]="processing" trans>Apply</button>
</mat-dialog-actions>
