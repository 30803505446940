import {TestimonialWidget, TestimonialWidgetElement} from './testimonial-widget.model';

export function generateTestimonialWidgetMarkup(testimonialWidget: TestimonialWidget): string {
    const buildConfigHtml = (interval: number, showIndicators: boolean, showControls: boolean) => `<div class="carousel__config" data-interval="${interval ?? ''}" data-showIndicators="${showIndicators ?? true}" data-showControls="${showControls ?? true}" data-ride="carousel"></div>`;
    const buildIndicators = (numberOfQuotes: number, testimonialId: string) => {
        let indicators = `<ol class="carousel-indicators mb-0">`;
        for (let i = 0; i < numberOfQuotes; i++) {
            indicators += `<li data-target="#${testimonialId}" data-bs-target="#${testimonialId}" data-slide-to="${i}" data-bs-slide-to="${i}" ${i === 0 ? 'class="active"' : ''}></li>`;
        }
        indicators += `</ol>`;

        return indicators;
    };
    const buildImage = (testimonial: TestimonialWidgetElement): string => {
        if (testimonial.imageSrc === undefined) {
            return '';
        }

        let result = '';

        if (testimonial.imageLink !== undefined) {
            result += `<a href='${testimonial.imageLink}' target="_blank">`;
        }

        result += `<img
                        src="${testimonial.imageSrc}"
                        alt="${testimonial.person}"
                        class="rounded-circle img-thumbnail mr-3">`;

        if (testimonial.imageLink !== undefined) {
            result += `</a>`;
        }

        return result;
    };

    const buildQuote = (quote: string): string => {
        return quote
            .trim()
            .replace(/^(<p.*?>)?/, '<p class="font-italic lead">');
    };

    const buildSlide = (testimonial: TestimonialWidgetElement, isActive: boolean): string => `<div class="carousel-item ${isActive ? 'active' : ''}">
            <div class="media">
                ${buildImage(testimonial)}
                <div class="media-body">
                    <blockquote class="blockquote border-0 p-0">
                        <div class="testimonial-quote">
                            ${buildQuote(testimonial.quote)}
                        </div>
                        <footer class="blockquote-footer mt-3">${testimonial.person}<br></footer>
                    </blockquote>
                </div>
            </div>
        </div>`;

    const buildControls = (testimonialId: string) => `
    <a class="carousel-control-prev width-auto" href="#${testimonialId}" role="button" data-slide="prev" data-bs-slide="prev">
        <i class="fa fa-angle-left text-dark text-lg follow-through"></i>
        <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next width-auto" href="#${testimonialId}" role="button" data-slide="next" data-bs-slide="next">
        <i class="fa fa-angle-right text-dark text-lg follow-through"></i>
        <span class="sr-only">Next</span>
    </a>`;

    const elements = [];

    elements.push(buildConfigHtml(testimonialWidget.interval, testimonialWidget.showIndicators, testimonialWidget.showControls));

    if (testimonialWidget.showIndicators) {
        elements.push(buildIndicators(testimonialWidget.elements.length, testimonialWidget.id));
    }

    elements.push('<div class="carousel-inner px-5 pb-4">');
    (testimonialWidget.elements ?? []).forEach(testimonial => {
        elements.push(buildSlide(testimonial, testimonial === testimonialWidget.elements[0]));
    });
    elements.push(`</div>`);

    if (testimonialWidget.showControls) {
        elements.push(buildControls(testimonialWidget.id));
    }

    return elements.join('\n');
}
